<template>
  <div class="password-reset-send-mail">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="page-title">
        <base-page-title>{{
          $t('passwordResetSendMail.pageTitle')
        }}</base-page-title>
      </div>
      <div class="password-reset-progress-bar">
        <progress-bar v-bind="progressBarData" />
      </div>
      <div class="password-reset-text">
        {{ $t('passwordResetSendMail.textEnterEmail') }}
      </div>
      <div class="password-reset-input-mail">
        <div class="label-field-wrapper">
          <div class="password-reset-input-label" data-test="label">
            {{ $t('common.labelEmail') }} <base-required-mark />
          </div>
          <div class="password-reset-input-mail-area">
            <validation-provider
              rules="requiredRule|emailRule"
              v-slot="{ errors }"
              ><base-text-input-mail-password-page v-model="email" />
              <div class="error" data-test="email-err">{{ $t(errors[0]) }}</div>
            </validation-provider>
          </div>
        </div>
      </div>
      <div class="submit-button">
        <base-decision-button
          :disabled="invalid || waitFlg"
          @click="handleSendMail"
          >{{ $t('common.buttonSubmit') }}</base-decision-button
        >
      </div>
      <div class="back-button">
        <base-cancel-button @click="backLoginTop">{{
          $t('common.buttonReturn')
        }}</base-cancel-button>
      </div>
    </validation-observer>
    <announce-popup
      v-if="alertFlg"
      :type="'failure'"
      :title="$t('common.error')"
      :buttons="buttons"
      @close="alertFlg = false"
      >{{ $t(popupMessage) }}</announce-popup
    >
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import BaseTextInputMailPasswordPage from '@/components/parts/atoms/BaseTextInputMailPasswordPage.vue'
import BaseRequiredMark from '@/components/parts/atoms/BaseRequiredMark.vue'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton.vue'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import ReloadConfirm from '@/components/mixins/ReloadConfirm'
import { getClinicData } from '@/components/mixins/ApiReset'
import { axiosObjectForNewLogin } from '@/utils/library'

export default {
  name: 'PasswordResetSendMail',

  components: {
    ValidationObserver,
    ValidationProvider,
    BasePageTitle,
    ProgressBar,
    BaseTextInputMailPasswordPage,
    BaseRequiredMark,
    BaseDecisionButton,
    BaseCancelButton,
    AnnouncePopup
  },

  mixins: [ReloadConfirm],

  data() {
    return {
      progressBarData: {
        barWidthPc: 'password-resetting-screen',
        textList: [
          'common.progressBarEnterEmail',
          'common.progressBarAuthentication',
          'common.progressBarNewPassword',
          'common.progressBarFinish'
        ],
        textOrangeNumber: 1
      },
      email: '',
      alertFlg: false,
      popupMessage: '',
      buttons: [this.$t('common.buttonClose')],
      waitFlg: false
    }
  },

  async created() {
    const shouldGetData =
      !this.$store.getters['auth/entranceToken'] && this.$route.query.clinic
    if (shouldGetData) await getClinicData(this.$route.query.clinic)
  },

  methods: {
    async handleSendMail() {
      this.waitFlg = true
      try {
        const axiosObject = axiosObjectForNewLogin()
        await axiosObject.post('/password-reset/send-mail', {
          email: this.email,
          lang: this.$i18n.locale
        })
        this.$router.push({ name: 'PasswordResetSendAfter' })
      } catch (ex) {
        if (ex.response?.status === 401) {
          this.buttons = [this.$t('common.buttonGoToLogin')]
        } else {
          this.popupMessage =
            ex.response?.data?.message === 'no data'
              ? 'passwordResetSendMail.popupMessageNotRegistered'
              : 'passwordResetSendMail.popupMessageUnableSend'
          this.buttons = [this.$t('common.buttonClose')]
        }
        this.alertFlg = true
        this.waitFlg = false
      }
    },
    backLoginTop() {
      const encryptedClinicId = !process.env.CI
        ? sessionStorage.getItem('encryptedClinicId')
        : 'clinicIdToken'
      this.$router.push({
        name: 'LoginTop',
        query: { clinic: encryptedClinicId }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.password-reset-send-mail {
  padding: 0 20px 100px;
  > .observer {
    > .page-title {
      margin-top: 70px;
    }
    > .password-reset-progress-bar {
      margin-top: 27px;
    }
    > .password-reset-text {
      margin: 39px auto 0;
      font-weight: bold;
      @include font-size(18);
    }
    > .password-reset-input-mail {
      margin-top: 38px;
      display: flex;
      justify-content: center;
      > .label-field-wrapper {
        width: 640px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0 25px;
        @media (max-width: $global-container) {
          width: 75vw;
          max-width: 410px;
          min-width: 280px;
          display: block;
          text-align: left;
        }
        > .password-reset-input-label {
          @include font-size(18);
          font-weight: bold;
        }
        > .password-reset-input-mail-area {
          flex: 1;
          max-width: 410px;
          min-width: 280px;
          font-weight: bold;
          @media (max-width: $global-media-width) {
            min-width: auto;
            max-width: none;
          }
          > span {
            position: relative;
            width: 100%;
            > .error {
              position: absolute;
              z-index: 100;
              text-align: left;
              color: #{$plus_red};
              font-size: 12px;
            }
          }
        }
      }
    }
    > .submit-button {
      margin-top: 79px;
    }
    > .back-button {
      margin-top: 23px;
    }
  }
}
</style>
